import React, { Component } from "react";
import { Chart } from "react-google-charts";
import LogicaGraficos from "../LogicaGraficos";

class BarraHorizontal extends Component {
  state = {
    chartData: [],
  };

  callBackFunction = (childData) => {
    this.setState({ chartData: childData });
    console.log(childData);
  };

  render() {
    return (
      <>
        <LogicaGraficos parentCallback={this.callBackFunction} tipo={"Barra"} />
        <div className="card_graph">
          <center>
            <p>Distribuição de atendimentos</p>
            <p className="card_subtitle">Dia da semana x tipo de atendimento</p>
          </center>
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="BarChart"
            loader={<div>Carregando Gráfico</div>}
            data={this.state.chartData}
            options={{
              backgroundColor: "rgb(255, 255, 255)",
              chartArea: {
                width: "84%",
              },
              legend: {
                position: "bottom",
              },
              // colors: ["#27b2db", "#fb534e", "#339966"],
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
        </div>
        <br />
      </>
    );
  }
}

export default BarraHorizontal;
