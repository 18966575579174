import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsAtendimento from "../../domain/actions/actionsAtendimento";
// import AccessTimeFilledTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
// import * as Icons from "@mui/icons-material";
import * as Icons from "react-icons/fa";
import { FormGroup } from "reactstrap";
import BotaoAtualizar from "../../components/BotaoAtualizar";
import BotaoCadastrar from "../../components/BotaoCadastrar";
import ModalCarregando from "../../components/ModalCarregando";
import "../../assets/styles/painelDeControle/index.css";
import Linha from "../../components/Graficos/Tipos/Linha";
import Barra from "../../components/Graficos/Tipos/Barra";
import BarraHorizontal from "../../components/Graficos/Tipos/BarraHorizontal";
import Combo from "../../components/Graficos/Tipos/Combo";
import Pizza from "../../components/Graficos/Tipos/Pizza";

const PainelDeControle = (props) => {
  const [idStatus, setStatusId] = useState("");

  //Ao montar/entrar componente
  useEffect(() => {
    props.pesquisarAtendimentosPorStatus();
    props.pesquisarTotalAtendimentos();
    props.pesquisarTotalAtendimentosPorTipos();
    props.pesquisarTotalMensalPorCliente();
  }, []);

  const newTo = {
    pathname: "/sac/atendimentos",
    state: { statusId: "1" },
  };

  var atendimentosPorStatus = [];
  if (props.atendimentosPesquisadosPorStatus)
    atendimentosPorStatus = props.atendimentosPesquisadosPorStatus;

  //Inicio Rotina de geração do gráfico

  var totalAtendimentosPorDia = [];
  if (props.totalAtendimentosPesquisados)
    totalAtendimentosPorDia = props.totalAtendimentosPesquisados;

  const DynamicFaIcon = ({ name }) => {
    const IconComponent = Icons[name];

    if (!IconComponent) {
      // Return a default one
      return <Icons.FaApple style={{ fontSize: 30 }} />;
    }

    return (
      <IconComponent style={{ fontSize: 24, marginRight: 5, marginLeft: 10 }} />
    );
  };

  function refreshAtendimento() {
    props.pesquisarAtendimentosPorStatus();
  }

  setInterval(refreshAtendimento, 1800000);

  return (
    <>
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="display-4 titulo">Controle de Atendimentos</h2>
        </div>
        <div>
          <FormGroup>
            <BotaoAtualizar
              onClickPesquisar={() => {
                props.pesquisarAtendimentosPorStatus();
              }}
            />
          </FormGroup>
        </div>
        <div className="ml-1">
          <FormGroup>
            <BotaoCadastrar
              uri="/sac/atendimentos-cadastrar"
              descricaoObjeto="Atendimento"
            />
          </FormGroup>
        </div>
      </div>
      <hr />
      <div className="row mb-3">
        {!props.atendimentosPesquisadosPorStatus && (
          <ModalCarregando isOpen={true} pagina="Listagem de Atendimentos" />
        )}
        {atendimentosPorStatus.map((atendimentos) => (
          <>
            <div className="col-lg-4 col-sm-6 mt-1">
              <Link
                to={{
                  pathname: `/sac/atendimentos`,
                  state: {
                    statusId: atendimentos.id,
                    page: 0,
                    pesquisa: props.atendimentosPesquisados,
                  },
                }}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={"card text-white"}
                  style={{ backgroundColor: atendimentos.corFundo }}
                >
                  <div className="card-body">
                    <span className="row">
                      {/* <AccessTimeFilledTwoToneIcon style={{ fontSize: 35 }} /> */}
                      <DynamicFaIcon name={atendimentos.icon} />
                      <h6 className="card-title mt-2 ml-2">
                        {atendimentos.situacao}
                      </h6>
                    </span>
                    <h2 className="lead">{atendimentos.totalServico}</h2>
                  </div>
                </div>
              </Link>
            </div>
          </>
        ))}
      </div>
      <div className="row mb-3 gx-0">
        <div className="col-lg-12">
          <BarraHorizontal />
        </div>
        <div className="col-lg-3 col-sm-12 mt-1 pr-2">
          <Barra />
        </div>
        <div className="col-lg-3 col-sm-12 mt-1 px-1">
          <Linha />
        </div>
        <div className="col-lg-3 col-sm-12 mt-1 px-1">
          <Combo />
        </div>
        <div className="col-lg-3 col-sm-12 mt-1 pl-2">
          <Pizza />
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario.usuarioLogado,
    atendimento: state.atendimento.atendimentos,
    usuariosPesquisados: state.usuario.usuariosPesquisados,
    naturezasPesquisados: state.natureza.naturezasPesquisados,
    clientesPesquisados: state.cliente.clientesPesquisados,
    statusAtendimentoPesquisados:
      state.statusAtendimento.statusAtendimentoPesquisados,
    atendimentosPesquisadosPorStatus:
      state.atendimento.atendimentosPesquisadosPorStatus,
  };
};

export default connect(mapStateToProps, actionsAtendimento)(PainelDeControle);
